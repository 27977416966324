import { i18n } from '@src/localization/config';

export const state = {
  matches: {},
  matchesCheckin: {},
  matchesPickBan: {},
  matchesGames: {},
  matchGameStatuses: {},
  matchProtestStatuses: {},
  matchCheckinModalId: null,
  hubMatchState: {},
};

export const getters = {
  getMatch: (state) => (id) => state.matches[id],
  getMatchCheckin: (state) => (id) => state.matchesCheckin[id],
  getMatchPickBan: (state) => (id) => state.matchesPickBan[id],
  getMatchGameStatus: (state) => (id) => state.matchGameStatuses[id],
  getMatchGame:
    (state) =>
    ({ matchId, gameIndex }) => {
      const match = state.matchesGames[matchId];
      return match && match[gameIndex];
    },

  getMatchProtests: (state) =>
    Object.values(state.matchProtestStatuses),
  getMatchProtest: (state) => (id) => state.matchProtestStatuses[id],
  getHubMatchState: (state) => state.hubMatchState,
};

export const mutations = {
  SET_MATCH_GAME_STATUSES(state, statuses) {
    state.matchGameStatuses = _.keyBy(statuses, 'id');
  },

  UPDATE_MATCHES(state, matches) {
    const mergedMatches = matches.map((match) =>
      _.merge({}, state.matches[match.id] || {}, match),
    );
    state.matches = {
      ...state.matches,
      ..._.keyBy(mergedMatches, 'id'),
    };
  },

  UPDATE_PICK_BAN(state, pickBan) {
    state.matchesPickBan = {
      ...state.matchesPickBan,
      ...pickBan,
    };
  },

  UPDATE_MATCH_CHECKIN(state, checkin) {
    state.matchesCheckin = {
      ...state.matchesCheckin,
      ...checkin,
    };
  },

  UPDATE_MATCH_STATISTIC(state, { id, ...statistics }) {
    state.matches[id].statistics = statistics;
  },

  SET_MATCH_RESULT_ID(state, { id, resultId }) {
    state.matches[id].resultId = resultId;
    state.matches = {
      ...state.matches,
      [id]: state.matches[id],
    };
  },

  SET_MATCH_TEAM_RESULT(state, { id, resultTeam, teamHash }) {
    state.matches[id].resultTeam = {
      [teamHash]: resultTeam,
    };
    state.matches = {
      ...state.matches,
      [id]: state.matches[id],
    };
  },

  UPDATE_MATCH_GAME(state, { id, game }) {
    state.matchesGames = _.merge({}, state.matchesGames, {
      [id.match]: {
        [game.index]: game,
      },
    });
  },

  UPDATE_MATCH_GAMES(state, { id, games }) {
    state.matchesGames = {
      ...state.matchesGames,
      [id]: _.keyBy(games, 'index'),
    };
  },

  UPDATE_MATCH_SERVER(state, { id, server }) {
    state.matches[id].server = server;
  },

  SET_MATCH_PROTEST_STATUSES(state, newValue) {
    state.matchProtestStatuses = newValue;
  },

  SET_MATCH_CHECKIN_MODAL_ID(state, id) {
    state.matchCheckinModalId = id;
  },

  SET_HUB_MATCH_STATE(state, newValue) {
    state.hubMatchState = newValue;
  },
};

export const actions = {
  fetchMatch({ commit, dispatch }, id) {
    // TODO изменения v2 для GOF
    return api
      .get(`v2/match/${id}`)
      .then(
        ({
          teams,
          tournament,
          match,
          round,
          games,
          server,
          anticheat,
          matchDemos,
          ingameLobby,
          hubMatchState,
        }) => {
          const users = teams.reduce(
            (arr, team) => arr.concat(team.players),
            [],
          );
          match.statistics = {};
          match.games = games;
          match.anticheat = anticheat;
          match.server = server;
          match.ingameLobby = ingameLobby;
          match.teams = teams.map((team) => ({
            hash: team.hash,
            side: team.side,
          }));
          (match.matchDemos = matchDemos),
            commit('UPDATE_MATCHES', [match]);

          commit('SET_HUB_MATCH_STATE', hubMatchState);

          dispatch('users/storeUsers', users, { root: true });
          dispatch('teams/storeTeams', teams, { root: true });
          dispatch('tournaments/storeTournament', tournament, {
            root: true,
          });

          return {
            ingameLobby,
            match,
            matchDemos,
            round,
            teams,
            tournament,
          };
        },
      )
      .catch(() => false);
  },

  fetchMatchUserAbilities({ commit }, id) {
    return api.get(`/match/${id}/abilities`).then((data) => {
      commit(
        'users/UPDATE_USERS_MATCHES',
        {
          [id]: data,
        },
        { root: true },
      );
    });
  },

  fetchMatchHSpickStatus({ commit }, id) {
    return api
      .get(`/match/${id}/heroes/pick/status`)
      .then(({ banCount, current, ...data }) => {
        const mapHeroes = (pick, ban) =>
          pick.map((hero) => ({
            name: hero,
            disabled: ban.includes(hero),
          }));

        commit('UPDATE_PICK_BAN', {
          [id]: {
            banCount,
            current,
            team1: {
              heroes: mapHeroes(data.team1.pick, data.team2.ban),
              ...data.team1,
            },
            team2: {
              heroes: mapHeroes(data.team2.pick, data.team1.ban),
              ...data.team2,
            },
          },
        });
      });
  },

  fetchMatchHSpickBanConfirm(context, { id, ban }) {
    return api.post(`/match/${id}/heroes/pick/ban`, { ban });
  },

  fetchWfPickbanStatus({ commit }, id) {
    return api.get(`/v2/match/${id}/map/pick/status`).then((data) => {
      commit('UPDATE_PICK_BAN', {
        [id]: {
          ...data.data,
          ...data,
        },
      });
    });
  },

  fetchWfPickbanConfirm(context, { id, value, action, sideCode }) {
    return api.post(`/v2/match/${id}/map/pick/action`, {
      class: sideCode,
      action,
      value,
    });
  },

  fetchMatchCheckinStatus({ commit }, id) {
    return api.get(`/match/${id}/checkin/status`).then((checkin) => {
      commit('UPDATE_MATCH_CHECKIN', { [id]: checkin });
    });
  },

  fetchMatchCheckinConfirm(context, id) {
    return api.post(`/match/${id}/checkin/confirm`).then(() => {});
  },

  fetchMatchChannels({ dispatch, commit }, id) {
    return api.get(`/match/${id}/channels`).then(({ channels }) => {
      channels.forEach(({ name }) => {
        socket.subscribe(name, ({ data }) => {
          dispatch('fetchMatchUserAbilities', id);

          switch (data.event.type) {
            case 'match.checkin':
            case 'match.confirmed':
              dispatch('fetchMatchCheckinStatus', id);
              break;
            case 'match.user_set_pick':
              dispatch('fetchMatchHSpickStatus', id);
              break;
            case 'match.pickban': {
              const pickban = data.event.data;
              commit('UPDATE_PICK_BAN', { [id]: pickban });
              break;
            }
            case 'match.serverUpdated':
              dispatch('fetchMatch', id);
              dispatch('fetchMatchUserAbilities', id);
              break;
            case 'match.server.start':
              commit('UPDATE_MATCH_SERVER', {
                id,
                server: data.event.data,
              });
              break;
            case 'ingameLobby.created':
              dispatch('fetchMatch', id);
              break;
            default:
          }
          return true;
        });
      });
    });
  },

  fetchMatchStatistic({ commit, dispatch }, id) {
    return api
      .get(`/v2/match/${id}/stats`)
      .then(({ games, between, matches }) => {
        games = games || [];
        matches = matches || [];

        const entities = games.reduce(
          (obj, item) => {
            obj.teams.push({
              name: item.name,
              image: item.imgLogo,
              hash: item.hash,
            });

            obj.users = obj.users.concat(
              item.players.map((player) => ({
                name: player.gameNick,
                hash: player.hash,
                image: player.avatarUrl,
                role: player.isCaptain ? 'captain' : '',
              })),
            );

            return obj;
          },
          {
            teams: [],
            users: [],
          },
        );

        const getTeamPoint = (match, side, hash) => {
          if (match.technicalDefeatHashList.includes(hash)) {
            return i18n.t('matches.tech_shortDefeat');
          }
          return match[`pointsTeam${side}`];
        };

        matches.map((match) => {
          const { enemies, matches } = match.matches.reduce(
            (obj, item) => {
              const enemyTeam = item.teams.find(
                ({ hash }) => hash !== match.hash,
              );
              obj.enemies.push(enemyTeam);
              item.match.enemyHash = enemyTeam.hash;

              obj.matches.push({
                ...item.match,
                points: {
                  [enemyTeam.hash]: getTeamPoint(
                    item.match,
                    enemyTeam.side,
                    enemyTeam.hash,
                  ),
                  [match.hash]: getTeamPoint(
                    item.match,
                    enemyTeam.side === 1 ? 2 : 1,
                    match.hash,
                  ),
                },
              });
              return obj;
            },
            {
              enemies: [],
              matches: [],
            },
          );
          entities.teams = entities.teams.concat(enemies);
          match.matches = matches;
          return match;
        });

        dispatch('teams/storeTeams', entities.teams, { root: true });
        dispatch('users/storeUser', entities.users, { root: true });

        commit('UPDATE_MATCH_STATISTIC', {
          id,
          games,
          between,
          matches: _.keyBy(matches, 'hash'),
        });
      });
  },

  matchCommonResult(
    { commit, dispatch },
    {
      id,
      teamHash,
      specialStatus,
      complaint,
      pointsTeamOne,
      pointsTeamTwo,
      imgScreens,
    },
  ) {
    return api
      .post(`/match/${id}/result/change`, {
        form: {
          specialStatus,
          complaint,
          pointsTeam1: pointsTeamOne,
          pointsTeam2: pointsTeamTwo,
          imgScreens: {
            add: imgScreens,
          },
        },
      })
      .then((result) => {
        const { resultId } = result;
        commit('SET_MATCH_RESULT_ID', { id, resultId });
        dispatch('showMatchTeamResult', { id, teamHash });
        return result;
      });
  },

  showMatchTeamResult({ commit }, { id, teamHash }) {
    return api.get(`/match/${id}/result/items`).then((result) => {
      const resultTeam = result.current;
      commit('SET_MATCH_TEAM_RESULT', { id, resultTeam, teamHash });
    });
  },

  fetchTeamFFAJudgeResult({ dispatch, commit }, id) {
    return api
      .get(`/v2/match/${id}/judge/result`)
      .then(async ({ teams, games }) => {
        dispatch('teams/storeTeams', teams, { root: true });
        commit('UPDATE_MATCH_GAMES', { id, games });
      });
  },

  fetchTeamFFAJudgeSaveResult(
    { commit },
    { id = {}, results = [], imgScreens = {} },
  ) {
    return api
      .post(`/v2/match/${id.match}/judge/result/${id.game}/save`, {
        results,
        imgScreens,
      })
      .then((res) => {
        const { game } = res;
        commit('UPDATE_MATCH_GAME', { id, game });
        return res;
      });
  },

  fetchMapList(context, id) {
    return api.get(`/game/${id}/directory`).then((data) => {
      const csgoServerConfig = data?.csgoServerConfig;
      const maps = Object.entries(data.modes).reduce(
        (result, [key, name]) => {
          result.push({
            disabled: true,
            name: i18n.tp(name),
          });
          const maps = data?.maps
            ?.filter(({ type }) => type === key)
            ?.map((map) => ({
              ...map,
              name: i18n.tp(map.name),
            }));
          result.push(...maps);
          return result;
        },
        [],
      );

      const sides = data.sides.map((side) => ({
        ...side,
        label: i18n.tp(side.label),
      }));

      return {
        maps,
        sides,
        csgoServerConfig,
      };
    });
  },
};
