export function pushEvents(evenAction, tmrParams) {
  if (process.env.NODE_ENV === 'development') return;

  // yandex
  window.yaCounter88495267.params({
    vkplay_events: evenAction,
  });

  // mail
  window._tmr?.push({
    type: 'setUserParams',
    params: { VKPlayID: window?.__GEMS?.current_user?.user_id },
  });
  window._tmr.push({
    id: '3252495',
    type: 'reachGoal',
    goal: 'vkplay',
    params: tmrParams,
    userid: window?.__GEMS?.current_user?.user_id,
  });
}
